import { register } from "swiper/element/bundle";

export function useSwipers() {
  onMounted(() => {
    // client only execution
    if (import.meta.client) {
      register();

      const swiperEl = document.querySelector("swiper-container");

      if (swiperEl) {
        swiperEl.initialize();
      }
    }
  });
}
