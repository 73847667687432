<script lang="ts" setup>
// https://swiperjs.com/element (NOTE: do not forget to add Swiper web components in nuxt.config.ts)
import { Image as DatocmsImage, type ResponsiveImageType } from "vue-datocms";
import type { ImageFileField } from "~/graphql/datocms/generated";
import type { NavigationPosition, SlideAspectRatio, SwiperImage } from "~/models/swiper";

const props = defineProps<{
  items?: Array<SwiperImage>;
  slidesPerView: number;
  slideAspectRatio: SlideAspectRatio;
  navigationPosition: NavigationPosition;
}>();

let currentSlidesPerView = props.slidesPerView;
let currentBreakpoints = {};

if (props.slidesPerView > 1) {
  currentSlidesPerView = 1;

  currentBreakpoints = {
    640: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: props.slidesPerView,
    },
  };
}
</script>

<template>
  <ClientOnly>
    <swiper-container
      :slides-per-view="currentSlidesPerView"
      :breakpoints="currentBreakpoints"
      :space-between="16"
      :centered-slides="false"
      :navigation="{}"
      :loop="true"
      :autoplay="true"
      :autoplay-delay="5000"
      :autoplay-disable-on-interaction="true"
      :class="`swiper__navigation-position--${navigationPosition}`"
    >
      <swiper-slide v-for="item in items" :key="item.id" :class="`swiper__slide-aspect-ratio--${slideAspectRatio}`">
        <NuxtLink v-if="item.linkItem?.href" :to="item.linkItem.href" :target="item.linkItem.target">
          <DatocmsImage
            v-if="item.image as ImageFileField"
            :data="item.image?.responsiveImage as ResponsiveImageType"
            object-fit="cover"
            :fade-in-duration="0"
            :use-placeholder="false"
            style="position: unset"
          />
        </NuxtLink>

        <DatocmsImage
          v-if="!item.linkItem?.href && item.image as ImageFileField"
          :data="item.image?.responsiveImage as ResponsiveImageType"
          object-fit="cover"
          :fade-in-duration="0"
          :use-placeholder="false"
          style="position: unset"
        />
      </swiper-slide>
    </swiper-container>

    <template #fallback>
      <img :src="items?.at(0)?.image?.responsiveImage.src" alt="" class="object-cover">
    </template>
  </ClientOnly>
</template>

<style scoped>

</style>
